import React from 'react';
import {Card, CardBody, CardHeader, CardTitle, Table} from "reactstrap";

const DuplicateShareVolume = ({ data, trades }) => {

  const time = data.lastTime;

  return (
    <Card>
      <CardHeader>
        <CardTitle tag="h2">Duplicate Order Volume</CardTitle>
        <p>Recurrence of same size orders across both sides of the order book.</p>
      </CardHeader>
      <CardBody>
        <Table className="tablesorter">
          <thead className="text-primary">
          <tr>
            <th>Order Size</th>
            <th>Recurrence</th>
          </tr>
          </thead>
          <tbody>
          { Object.keys(data.all.washSalePoints[time]).map((key) => {
            const itemAll = data.all.washSalePoints[time][key];

            return (
              <tr>
                <td>{key}</td>
                <td>{itemAll}</td>
              </tr>
            );
          })}
          </tbody>
        </Table>
      </CardBody>
    </Card>
  )
};

export default DuplicateShareVolume;
