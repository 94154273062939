
import React, { useEffect, useState } from "react";
import axios from 'axios';

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  UncontrolledAlert
} from "reactstrap";

import OrderFlow from "../components/OrderFlow";
import DuplicateShareVolume from "../components/DuplicateShareVolume";
import BuySellRatio from "../components/BuySellRatio";
import AdminNavbar from "../components/Navbars/AdminNavbar";
import PriceChart from "../components/PriceChart";
import OrderBubbles from "../components/OrderBubbles";
import Technicals from "../components/Indicators";
import MarketNotification from "../components/MarketNotification";
import LargeOrders from "../components/LargeOrders";
import ExponentialPriceChart from "../components/ExponentialPriceChart";
import OrdersByExchange from "../components/OrdersByExchange";
import TradeBreakdown from "../components/LargeOrders/tradeBreakdown";
import SharePriceNYSE from "../components/SharePrice/nyse";
import SharePriceFrankfurt from "../components/SharePrice/frankfurt";
import LargeTrades from "../components/LargeTrades";

function Dashboard(props) {
  const [data, setData] = useState(null);
  const [trades, setTrades] = useState([]);
  const [technicals, setTechnicals] = useState(null);
  const [expoChart, setExpoChart] = useState(null);
  const [exchange, setExchange] = useState(null);
  const [germany, setGermany] = useState(null);

  useEffect(async () => {
    let isMounted = true;

    const fetchTechnicals =  async () => {
      try {
        const result = await axios(
          'https://api.l2insight.com/technicals.json',
        );

        setTechnicals(result.data);
      } catch (error) {
        console.log(error);
      }
    };

    const fetchExpoChart =  async () => {
      try {
        const result = await axios(
          'https://api.l2insight.com/expo-chart.json',
        );

        setExpoChart(result.data);
      } catch (error) {
        console.log(error);
      }
    };

    const fetchExchange =  async () => {
      try {
        const result = await axios(
          `https://api.l2insight.com/exchange.json?timestamp=${new Date().getTime()}`,
        );

        setExchange(result.data);
      } catch (error) {
        console.log(error);
      }
    };

    if (technicals === null) {
      await fetchTechnicals();
      await fetchExpoChart();
      await fetchExchange();
    }

    const intervalId = setInterval(() => {  //assign interval to a variaable to clear it
      fetchExchange();
    }, 600000);

    return () => {
      clearInterval(intervalId); //This is important
      isMounted = false // Let's us know the component is no longer mounted.
    }
  }, []);

  useEffect(async () => {
    let isMounted = true;

    const fetchData = async () => {
      if(!isMounted) return;

      if (!document.hidden) {

        try {
          const result = await axios(
            `https://api.l2insight.com/?timestamp=${new Date().getTime()}`,
          );

          setData(result.data);
        } catch (error) {
          console.log(error);
        }

        try {
          const result = await axios(
            `https://api.l2insight.com/gs2c.json?timestamp=${new Date().getTime()}`,
          );

          setGermany(result.data);
        } catch (error) {
          console.log(error);
        }

        try {
          const tradesResponse = await axios(
            `https://api.l2insight.com/trades.json?timestamp=${new Date().getTime()}`,
          );

          if (typeof tradesResponse.data !== 'string' &&
            tradesResponse.data.length >= 1) {

            if (trades.length === 0 || tradesResponse[tradesResponse.length - 1].timestamp > trades[trades.length - 1].timestamp) {
              setTrades(tradesResponse.data.reverse());
            }
          }
        } catch (error) {
          console.log(error);
        }
      }
    };

    fetchData();

    const intervalId = setInterval(() => {  //assign interval to a variaable to clear it
      fetchData();
    }, 1500);

    return () => {
      clearInterval(intervalId); //This is important
      isMounted = false // Let's us know the component is no longer mounted.
    }
  }, []);

  if (data === null || technicals === null) {
    return <div/>;
  }

  return (
    <>
      <AdminNavbar/>
      <div className="content">
        <Row>
          <Col xs="12">
            <UncontrolledAlert color="default">
                    <span>
                      <strong>The data presented on this site is purely served for entertainment purposes only and should not be used to formulate or execute investment decisions! <br />The creators of this site accept no liability for the veracity of the data or it's availability. You acknowledge that you use the information <strong>at your own risk</strong>.</strong>
                    </span>
            </UncontrolledAlert>
          </Col>

          <MarketNotification data={data} />

          <SharePriceNYSE data={data} />
          <SharePriceFrankfurt data={data} germany={germany} />

        </Row>

        <Row>
          <PriceChart data={data} />
        </Row>

        <Technicals technicals={technicals} />

        <Row>
          <Col lg="4" md="12">
            <OrdersByExchange exchange={exchange} />
            <TradeBreakdown trades={trades} />
            <DuplicateShareVolume data={data} trades={trades}/>
          </Col>
          <Col lg="8" md="12">
            <LargeOrders trades={trades} data={data} />
            <LargeTrades exchange={exchange} />
            <BuySellRatio data={data} trades={trades} />
          </Col>
        </Row>

        <Row>
          <Col>
            <OrderBubbles data={data} title="Order Distribution" />
          </Col>
        </Row>

        <Row>
          <OrderFlow data={data} trades={trades}/>
        </Row>
      </div>
    </>
  );
}

export default Dashboard;
