/*!

=========================================================
* Black Dashboard React v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes

// reactstrap components
import {
  NavbarBrand,
  Navbar,
  Container, CardTitle,
} from "reactstrap";

function AdminNavbar() {
  return (
    <>
      <Navbar className="navbar-absolute navbar-transparent" expand="lg">
        <Container fluid>
          <div className="navbar-wrapper">
            <NavbarBrand href="#pablo" onClick={(e) => e.preventDefault()}>
              <strong>APEBOARD</strong> | GME Insights
            </NavbarBrand>
          </div>
        </Container>
      </Navbar>
    </>
  );
}

export default AdminNavbar;
