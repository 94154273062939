import React from 'react';
import {Card, CardBody, CardHeader, CardTitle, Table} from "reactstrap";

const volumeFormat = new Intl.NumberFormat('en-US', {
  style: 'decimal',
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
});

const currencyFormat = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
});

const LargeTrades = ({ exchange }) => {
  if (exchange === null || typeof exchange.trades === 'undefined' || exchange.trades.length === 0) {
    return <div />
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle tag="h2">Largest Trades</CardTitle>
        <p>Largest trades made during normal market hours</p>
      </CardHeader>
      <CardBody>
        <Table className="tablesorter">
          <thead className="text-primary">
          <tr>
            <th>Time</th>
            <th>Size</th>
            <th>Price</th>
            <th>Total Value</th>
            <th>Exchange</th>
          </tr>
          </thead>
          <tbody>
          {
            exchange.trades.map((item) => (
              <tr>
                <td>{ new Date(item.time).toLocaleTimeString('en-US') }</td>
                <td>{ volumeFormat.format(item.size) }</td>
                <td>{ currencyFormat.format(item.price) }</td>
                <td>{ currencyFormat.format(item.price * item.size) }</td>
                <td>{ item.venueName }</td>
              </tr>
            ))
          }
          </tbody>
        </Table>
      </CardBody>
    </Card>
  )
};

export default LargeTrades;
