import React from 'react';
import {Card, CardBody, CardHeader, CardTitle, Table} from "reactstrap";
const RapidFire = ({ data }) => {

  const time = data.lastTime;

  return (
    <Card>
      <CardHeader>
        <CardTitle tag="h2">Rapid Fire Orders</CardTitle>
        <p>Orders within a distance of the current price put into the book within quick succession of each other</p>
      </CardHeader>
      <CardBody>
        <Table className="tablesorter">
          <thead className="text-primary">
          <tr>
            <th>Proximity to Price</th>
            <th>Strikes Affected</th>
            <th># of Orders</th>
            <th>Shares</th>
          </tr>
          </thead>
          <tbody>
          { Object.keys(data.all.multipleOrdersInTime[time]).map((key) => {
            const itemAll = data.all.multipleOrdersInTime[time][key];

            return (
              <tr>
                <td>{key}%</td>
                <td>{itemAll.affectedStrikes}</td>
                <td>{itemAll.orders}</td>
                <td>{itemAll.shares}</td>
              </tr>
            );
          })}
          </tbody>
        </Table>
      </CardBody>
    </Card>
  )
};

export default RapidFire;
