import React from 'react';
import {Col, UncontrolledAlert} from "reactstrap";
import TimeAgo from 'javascript-time-ago'

// English.
import en from 'javascript-time-ago/locale/en'

TimeAgo.addLocale(en);

// Create formatter (English).
const timeAgo = new TimeAgo('en-US');


const calculateMessage = (tradingPeriods) => {
  const time = (new Date().getTime() / 1000).toFixed(0);
  let date = null;
  let duration = null;

  if (time < tradingPeriods.pre.start) {
    date = new Date(tradingPeriods.pre.start * 1000);
    duration = timeAgo.format(date);

    return `Pre-market session opens ${duration} @ ${date.toTimeString()}`;
  }

  if (time < tradingPeriods.regular.start) {
    date = new Date(tradingPeriods.regular.start * 1000);
    duration = timeAgo.format(date);

    return `Main market session begins ${duration} @ ${date.toTimeString()}`;
  }

  if (time < tradingPeriods.regular.end) {
    date = new Date(tradingPeriods.regular.end * 1000);
    duration = timeAgo.format(date);

    return `Main session ends ${duration} @ ${date.toTimeString()}`;
  }

  if (time < tradingPeriods.post.end) {
    date = new Date(tradingPeriods.post.end * 1000);
    duration = timeAgo.format(date);

    return `Post-market session ends ${duration} @ ${date.toTimeString()}`;
  }

  return null;
};

const MarketNotification = ({ data }) => {
  const message = calculateMessage(data.chart.currentTradingPeriod);

  if (!message) {
    return <div />
  }

  return (
    <Col xs="12">
      <UncontrolledAlert color="info">
                    <span>
                      <strong>{ message }</strong>
                    </span>
      </UncontrolledAlert>
    </Col>
  )
};

export default MarketNotification;
