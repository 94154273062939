import React from 'react';
import {Card, CardBody, CardHeader, CardTitle, Col, Row} from "reactstrap";

const normaliseMarketText = (text) => {
  if (text === 'PRE') return 'Pre-market';
  if (text === 'POST') return 'After Hours';
  if (text === 'REGULAR') return 'Main Session';
  if (text === 'POSTPOST') return 'Closed';

  return text;
};

const calculatePercentage = (data) => {
  if (data.chart.meta.marketState === 'PRE' && typeof data.chart.meta.preMarketChangePercent !== 'undefined') {
    return data.chart.meta.preMarketChangePercent.toFixed(2);
  }

  return (
    (
      (
        data.price[data.lastTime].toFixed(2) / data.chart.meta.regularMarketPreviousClose
      ) * 100
    ) - 100
  ).toFixed(2);
};

const SharePriceNYSE = ({ data }) => {
  const percentage = calculatePercentage(data);

  return (
    <React.Fragment>
      <Col xl="6" xs="12" className="share-price-container">
        <div className="item-header">
          <p className="market-state-text">{ normaliseMarketText(data.chart.meta.marketState) }</p>
          <p>GME <small>New York Stock Exchange</small></p>
        </div>
        <Row>
          <Col md="6" sm="12" className="price-container">
            <Card className="number-card">
              <CardHeader>
                <CardTitle tag="h2">
                  Share Price
                </CardTitle>
              </CardHeader>
              <CardBody>
                {  new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD',
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                }).format(data.price[data.lastTime]) }
                <span style={{ fontSize: '0.8em', paddingLeft: '0.5em', color: percentage < 0 ? '#c0392b' : '#218c74' }}>{ percentage }%</span>
              </CardBody>
            </Card>
          </Col>
          <Col md="3" sm="6" xs="6" className="bid-container">
            <Card className="number-card">
              <CardHeader>
                <CardTitle tag="h2">
                  Bid
                </CardTitle>
              </CardHeader>
              <CardBody>
                { data.raw.buy.length === 0 ? 0 : new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD',
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                }).format(data.raw.buy[0].price) }
              </CardBody>
            </Card>
          </Col>
          <Col md="3" sm="6" xs="6" className="ask-container">
            <Card className="number-card">
              <CardHeader>
                <CardTitle tag="h2">
                  Ask
                </CardTitle>
              </CardHeader>
              <CardBody>
                { data.raw.sell.length === 0 ? 0 : new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD',
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                }).format(data.raw.sell[0].price) }
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Col>


    </React.Fragment>
  )
};

export default SharePriceNYSE;
