import React from 'react';
import {Card, CardBody, CardHeader, CardTitle, Table} from "reactstrap";

const tradeNameLinker = (tradeName) => {
  let link = null;

  if (tradeName === 'Form-T Trade') {
    link = 'https://www.investopedia.com/terms/f/formt.asp';
  }

  if (tradeName === 'Intermarket Sweep Order') {
    link = 'https://ibkr.info/node/1734';
  }

  if (tradeName === 'Odd Lot Trade') {
    link = 'https://www.investopedia.com/terms/o/oddlot.asp';
  }

  if (link !== null) {
    return <td>
      <a href={link} target="_blank">{ tradeName }</a>
    </td>;
  }

  return <td>{ tradeName }</td>;
};

const TradeBreakdown = ({ trades }) => {
  const counts = {};

  const tradesCount = trades.length;

  trades.forEach((trade) => {
    trade.codes.forEach((code) => {
      if (typeof counts[code] === 'undefined') {
        counts[code] = 0;
      }

      counts[code] += 1;
    });
  });

  return (
    <Card>
      <CardHeader>
        <CardTitle tag="h2">Trade Type Breakdown</CardTitle>
        <p>Breakdown of trade types for the last 50 trades</p>
      </CardHeader>
      <CardBody>
        <Table className="tablesorter">
          <thead className="text-primary">
          <tr>
            <th>Type</th>
            <th>% of Trades</th>
          </tr>
          </thead>
          <tbody>
          { Object.keys(counts).sort().map((key) => {
            const count = counts[key];

            return (
              <tr>
                { tradeNameLinker(key) }
                <td>{ ((count / tradesCount) * 100).toFixed(0) }%</td>
              </tr>
            );
          })}
          </tbody>
        </Table>
      </CardBody>
    </Card>
  )
};

export default TradeBreakdown;
