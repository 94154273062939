import React from 'react';
import { Col, Row } from "reactstrap";
import Items from "./largeOrders";

const LargeOrders = ({ data }) => {
  return (
    <Row>
      <Col lg="6" md="12">
        <Items data={data} type="buy" title="Large Buy Orders" />
      </Col>
      <Col lg="6" md="12">
        <Items data={data} type="sell" title="Large Sell Orders" />
      </Col>
    </Row>

  )
};

export default LargeOrders;

