import React from 'react';
import {Card, CardBody, CardHeader, CardTitle, Table} from "reactstrap";

const formatter = new Intl.NumberFormat('en-US', {
  style: 'decimal',
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
});

const OrdersByExchange = ({ exchange }) => {
  const date = new Date(new Date().toLocaleString('en-US', { timeZone: 'America/new_york' }));
  const dateString = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

  if (exchange === null || typeof exchange.venue === 'undefined' || typeof exchange.venue[dateString] === 'undefined') {
    return <div />
  }

  if (typeof exchange.trades === 'undefined' || exchange.trades.length === 0) {
    return <div />
  }

  const exchangeData = Object.keys(exchange.venue[dateString]).sort(function(a, b) {
    return exchange.venue[dateString][b.volume] - exchange.venue[dateString][a.volume];
  });

  return (
    <Card>
      <CardHeader>
        <CardTitle tag="h2">Trades By Exchange</CardTitle>
        <p>Volume of trades, split out by source exchange.</p>
      </CardHeader>
      <CardBody>
        <Table className="tablesorter">
          <thead className="text-primary">
          <tr>
            <th>Order Size</th>
            <th>Volume</th>
            <th>%</th>
          </tr>
          </thead>
          <tbody>
          { exchangeData.map((key) => {
            const itemAll = exchange.venue[dateString][key];

            return (
              <tr>
                <td><strong>{ itemAll.venueName }</strong></td>
                <td>{formatter.format(itemAll.volume)}</td>
                <td>{ (itemAll.marketPercent * 100).toFixed(2) }%</td>
              </tr>
            );
          })}
          </tbody>
        </Table>
      </CardBody>
    </Card>
  )
};

export default OrdersByExchange;
