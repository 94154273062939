import React, { useState } from 'react';
import {Card, CardBody, CardHeader, CardTitle, Col, Row, Table} from "reactstrap";

const RSI = (technicals) => {
  const pickColor = (number) => {
    if (number > 70) return '#e74c3c';
    if (number < 30) return '#e74c3c';

    return '#fff';
  };

  return (
    <Col xl="2" lg="4" md="4" sm="6" xs="6">
      <Card className="number-card">
        <CardHeader>
          <CardTitle tag="h2">
            <span className="title-1">RSI</span>
            <div className="dayweek title">Day<span>Week</span></div>
          </CardTitle>
        </CardHeader>
        <CardBody>
          <div className="dayweek" style={{ color: pickColor(technicals['RSI'].day) }}>
            { technicals['RSI'].day.toFixed(0) }
            <span style={{ color: pickColor(technicals['RSI'].week) }}>{ technicals['RSI'].week.toFixed(0) }</span>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

const UltimateOscilator = (technicals) => {
  const pickColor = (number) => {
    if (number > 70) return '#e74c3c';
    if (number < 30) return '#e74c3c';

    return '#fff';
  };

  return (
    <Col xl="2" lg="4" md="4" sm="6" xs="6">
      <Card className="number-card">
        <CardHeader>
          <CardTitle tag="h2">
            Ultimate Oscillator
            <div className="dayweek title">Day<span>Week</span></div>
          </CardTitle>
        </CardHeader>
        <CardBody>
          <div className="dayweek" style={{ color: pickColor(technicals['UO'].day) }}>
            { technicals['UO'].day.toFixed(0) }
            <span style={{ color: pickColor(technicals['UO'].week) }}>{ technicals['UO'].week.toFixed(0) }</span>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

const Stochastic = (technicals) => {
  const pickColor = (number) => {
    if (number > 80) return '#e74c3c';
    if (number < 20) return '#e74c3c';

    return '#fff';
  };

  return (
    <Col xl="2" lg="4" md="4" sm="6" xs="6">
      <Card className="number-card">
        <CardHeader>
          <CardTitle tag="h2">
            Stochastic %k
            <div className="dayweek title">Day<span>Week</span></div>
          </CardTitle>
        </CardHeader>
        <CardBody>
          <div className="dayweek" style={{ color: pickColor(technicals['Stoch.K'].day) }}>
            { technicals['Stoch.K'].day.toFixed(0) }
            <span style={{ color: pickColor(technicals['Stoch.K'].week) }}>{ technicals['Stoch.K'].week.toFixed(0) }</span>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

const BBPower = (technicals) => (
  <Col xl="2" lg="4" md="4" sm="6" xs="6">
    <Card className="number-card">
      <CardHeader>
        <CardTitle tag="h2">
          Bull/Bear Power
          <div className="dayweek title">Day<span>Week</span></div>
        </CardTitle>
      </CardHeader>
      <CardBody>
        <div className="dayweek" style={{ color: technicals['BBPower'].day > 0 ? '#1abc9c' : '#e74c3c' }}>
          { technicals['BBPower'].day.toFixed(0) }
          <span style={{ color: technicals['BBPower'].week > 0 ? '#1abc9c' : '#e74c3c' }}>{ technicals['BBPower'].week.toFixed(0) }</span>
        </div>
      </CardBody>
    </Card>
  </Col>
);

const Technicals = ({ technicals }) => {
  if (technicals === null) {
    return <div />
  }

  return (
    <React.Fragment>
      <Row>
        { RSI(technicals) }
        <Col xl="2" lg="4" md="4" sm="6" xs="6">
          <Card className="number-card">
            <CardHeader>
              <CardTitle tag="h2">
                MACD
                <div className="dayweek title">Day<span>Week</span></div>
              </CardTitle>
            </CardHeader>
            <CardBody>
              <div className="dayweek">
                { technicals['MACD.macd'].day.toFixed(0) }
                <span>{ technicals['MACD.macd'].week.toFixed(0) }</span>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col xl="2" lg="4" md="4" sm="6" xs="6">
          <Card className="number-card">
            <CardHeader>
              <CardTitle tag="h2">
                MACD Signal
                <div className="dayweek title">Day<span>Week</span></div>
              </CardTitle>
            </CardHeader>
            <CardBody>
              <div className="dayweek">
                { technicals['MACD.signal'].day.toFixed(0) }
                <span>{ technicals['MACD.signal'].week.toFixed(0) }</span>
              </div>
            </CardBody>
          </Card>
        </Col>
        { UltimateOscilator(technicals) }
        { Stochastic(technicals) }
        { BBPower(technicals) }
      </Row>
    </React.Fragment>
  )
};

export default Technicals;
