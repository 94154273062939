import React, { useState } from "react";

import Konami from 'react-konami-code';
import Dashboard from './Dashboard';

import LongWeekendGif from './LongWeekend.gif';

const maintenancePageText = (show) => {
  const date = new Date(new Date().toLocaleString("en-US", {timeZone: "America/New_York"}));

  if (show === true || date.getDay() >= 1 && date.getDay() < 6) {
    return <Dashboard />;
  }

  return (
    <>
      <div className="content" style={{ paddingTop: '2em' }}>
        <p style={{ paddingBottom: '2em' }}>↑ ↑ ↓ ↓</p>
        <h1>Weekend Mode</h1>
        <p>It's the weekend, the stonk market is closed, the dashboard is therefore useless to you right now.</p>
        <img src={LongWeekendGif} style={{ marginTop: '1em', width: '400px', maxWidth: '100%'}} />
        <p style={{ paddingTop: '2em' }}>↑ ↑ ↓ ↓</p>
      </div>
    </>
  );
};

function Maintenance(props) {
  const [show, setShow] = useState(false);

  const hideMaintenance = () => {
    setShow(true);
  };

  return (
    <>
      <Konami action={hideMaintenance} code={[38,38,40,40,37,39,37,39]} />
      { maintenancePageText(show) }
    </>
  );
}

export default Maintenance;
