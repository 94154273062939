import React from 'react';
import {Card, CardBody, CardHeader, CardTitle, Table} from "reactstrap";
const LargeOrders = ({ data, type, title }) => {
  const clone = [...data.raw[type]];

  const buyOrders = clone.sort((a,b) => (b.price * b.shares) - (a.price * a.shares)).slice(0, 5);

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  });

  return (
    <Card>
      <CardHeader>
        <CardTitle tag="h2">{ title }</CardTitle>
        <p>Top 5 largest { type } price points in the order book, ordered by total value.</p>
      </CardHeader>
      <CardBody>
        <Table className="tablesorter">
          <thead className="text-primary">
          <tr>
            <th>Price</th>
            <th>Shares</th>
            <th>Orders</th>
            <th>Total Value</th>
          </tr>
          </thead>
          <tbody>
          {
            buyOrders.map((item) => (
              <tr>
                <td><strong>${item.price.toFixed(2)}</strong></td>
                <td>{ item.shares }</td>
                <td>{ item.orders }</td>
                <td>{formatter.format(item.price * item.shares)}</td>
              </tr>
            ))
          }
          </tbody>
        </Table>
      </CardBody>
    </Card>
  )
};

export default LargeOrders;
