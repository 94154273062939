import React from 'react';
import { Card, CardBody, CardHeader, CardTitle, Col } from "reactstrap";
import { Bubble } from "react-chartjs-2";

const OrderBubbles = ({ data, title }) => {
  const sizer = (number) => {
    if (number < 10) return 1;
    if (number < 50) return 2;
    if (number < 100) return 3;
    if (number < 200) return 4;
    if (number < 300) return 5;
    if (number < 400) return 6;
    if (number < 500) return 7;
    if (number < 600) return 8;
    if (number < 700) return 8;
    if (number < 800) return 8;
    if (number < 900) return 9;
    if (number < 1000) return 10;
    if (number < 1500) return 12;
    if (number < 2000) return 14;

    return 12;
  };


  const chartData = () => {
    return {
      datasets: [{
        label: 'Bid',
        data: data.raw['buy'].map((item) => ({
            x: item.price.toFixed(2),
            y: item.orders,
            r: sizer(item.shares),
        })),
        backgroundColor: '#218c74'
      },
        {
          label: 'Ask',
          data: data.raw['sell'].map((item) => ({
            x: item.price.toFixed(2),
            y: item.orders,
            r: sizer(item.shares),
          })),
          backgroundColor: '#c0392b'
        }]
    }
  };

  const options = {
    maintainAspectRatio: false,
    tooltips: {
      enabled: false,
    },
  };

  return (
    <Card className="card-chart">
      <CardHeader>
        <CardTitle tag="h2">
          {title}
        </CardTitle>
        <p>This chart show how bid and ask orders are distributed, x-axis is price, y-axis is number of orders.</p>
      </CardHeader>
      <CardBody>
        <div className="chart-area">
          <Bubble
            data={chartData}
            options={options}
            legend={{ display: false }}
          />
        </div>
      </CardBody>
    </Card>
  )
};

export default OrderBubbles;
