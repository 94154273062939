import React from 'react';
import { Card, CardBody, CardHeader, CardTitle, Col } from "reactstrap";
import { Line } from "react-chartjs-2";
import { chartExample2 } from "../../variables/charts";

const PriceChart = ({ data }) => {
  const preMarketDate = new Date(new Date().toLocaleString('en-US', { timeZone: 'America/new_york' }));
  preMarketDate.setHours(4, 0, 0, 0);

  const timestamps = [];
  if (typeof data.chart === 'undefined' || typeof data.chart.data.t === 'undefined') {
    return <div />
  }

  data.chart.data.t.forEach((ts) => {
    timestamps.push(ts * 1000)
  });

  timestamps.push(new Date().getTime());

  const chartItems = data.chart.data.h;
  chartItems.push(data.price[data.lastTime].toFixed(2));

  const chartData = (canvas) => {
    let ctx = canvas.getContext("2d");

    let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

    gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
    gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.0)");
    gradientStroke.addColorStop(0, "rgba(29,140,248,0)");

    return {
      labels: timestamps.map((ts) => {
        return new Date(ts);
      }),
      datasets: [
        {
          label: "Price",
          fill: true,
          backgroundColor: gradientStroke,
          borderColor: "#1f8ef1",
          borderWidth: 2,
          borderDash: [],
          borderDashOffset: 0.0,
          pointBackgroundColor: "#1f8ef1",
          pointBorderColor: "rgba(255,255,255,0)",
          pointHoverBackgroundColor: "#1f8ef1",
          pointBorderWidth: 2,
          pointHoverRadius: 1,
          pointHoverBorderWidth: 1,
          pointRadius: 1,
          data: chartItems,
        },
      ],
    };
  };

  if(chartItems.length <= 1) {
    return <div />
  }

  return (
    <Col lg="12">
      <Card className="card-chart">
        <CardHeader>
          <CardTitle tag="h2">
            <strong>Price Action</strong> | Today
          </CardTitle>
        </CardHeader>
        <CardBody>
          <div className="chart-area">
            <Line
              data={chartData}
              legend={{ display: false }}
              options={chartExample2.options}
            />
          </div>
        </CardBody>
      </Card>
    </Col>
  )
};

export default PriceChart;
