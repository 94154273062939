import React from 'react';
import {Card, CardBody, CardHeader, CardTitle, Table} from "reactstrap";
const Ratio = ({ data }) => {

  const time = data.lastTime;

  return (
    <Card>
      <CardHeader>
        <CardTitle tag="h2">Buy:Sell Ratio</CardTitle>
        <p>The number of orders sitting within range of the current price</p>
      </CardHeader>
      <CardBody>
        <Table className="tablesorter">
          <thead className="text-primary">
          <tr>
            <th>Proximity to Price</th>
            <th>Buy Orders</th>
            <th>Sell Orders</th>
            <th>All Orders</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>5%</td>
            <td>{ data.buy.ordersInRange[time][5].orders } ({ data.buy.ordersInRange[time][5].bookPercentage }%)</td>
            <td>{ data.sell.ordersInRange[time][5].orders } ({ data.sell.ordersInRange[time][5].bookPercentage }%)</td>
            <td>{ data.all.ordersInRange[time][5].orders } ({ data.all.ordersInRange[time][5].bookPercentage }%)</td>
          </tr>
          <tr>
            <td>10%</td>
            <td>{ data.buy.ordersInRange[time][10].orders } ({ data.buy.ordersInRange[time][10].bookPercentage }%)</td>
            <td>{ data.sell.ordersInRange[time][10].orders } ({ data.sell.ordersInRange[time][10].bookPercentage }%)</td>
            <td>{ data.all.ordersInRange[time][10].orders } ({ data.all.ordersInRange[time][10].bookPercentage }%)</td>
          </tr>
          <tr>
            <td>20%</td>
            <td>{ data.buy.ordersInRange[time][20].orders } ({ data.buy.ordersInRange[time][20].bookPercentage }%)</td>
            <td>{ data.sell.ordersInRange[time][20].orders } ({ data.sell.ordersInRange[time][20].bookPercentage }%)</td>
            <td>{ data.all.ordersInRange[time][20].orders } ({ data.all.ordersInRange[time][20].bookPercentage }%)</td>
          </tr>
          </tbody>
        </Table>
      </CardBody>
    </Card>
  )
};

export default Ratio;
