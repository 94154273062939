import React, { useState } from 'react';
import {Card, CardBody, CardHeader, CardTitle, Col, Button, Table} from "reactstrap";

const Side = (type, title, data) => {
  const [visible, setVisible] = useState(false);
  let itterator = 0;
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  });

  return (
    <Card style={{ background: type === 'buy' ? '#218c74' : '#c0392b' }}>
      <CardHeader>
        <CardTitle tag="h2">{ title }</CardTitle>
      </CardHeader>
      <CardBody>
        <Table className="tablesorter">
          <thead className="text-primary">
          <tr>
            <th>Price</th>
            <th>Total Value</th>
            <th>Shares</th>
            <th>Orders</th>
          </tr>
          </thead>
          <tbody>
          { data.raw[type].map((item) => {
            itterator += 1;

            const style = itterator > 15 && visible === false ? { display: 'none' } : {};

            return (
              <tr style={style}>
                <td><strong>${item.price.toFixed(2)}</strong></td>
                <td>{formatter.format(item.price * item.shares)}</td>
                <td>{item.shares}</td>
                <td>{item.orders}</td>
              </tr>
            );
          })}
          </tbody>
        </Table>
      </CardBody>
      <div style={{ display: 'none' }}>
        <Button color="primary"
                id="0"
                style={{width: '96%', borderRadius: 0, background: '#4834d4', margin: '2%', marginTop: 0 }}
                onClick={() => { setVisible(true); }}
                size="md">Show All ({data.raw[type].length})</Button>
      </div>
    </Card>
  );
};

const TradeTable = (trades) => {
  const [visible, setVisible] = useState(false);
  let itterator = 0;
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  });

  if (typeof trades !== 'object') {
    return <div />;
  }

  const items = [...trades];

  return (
    <Card>
      <CardHeader>
        <CardTitle tag="h2">Processed Trades</CardTitle>
      </CardHeader>
      <CardBody>
        <Table className="tablesorter">
          <thead className="text-primary">
          <tr>
            <th>Time</th>
            <th>Price</th>
            <th>Total Value</th>
            <th>Shares</th>
          </tr>
          </thead>
          <tbody>
          { items.map((item) => {
            itterator += 1;

            const style = itterator > 15 && visible === false ? { display: 'none' } : {};

            return (
              <React.Fragment>
                <tr style={style}>
                  <td>{ new Date(item.timestamp).toLocaleTimeString('en-US').replace('AM', '').replace('PM', '') }</td>
                  <td><strong>${item.price.toFixed(2)}</strong></td>
                  <td>{formatter.format(item.price * item.shares)}</td>
                  <td>{item.shares}</td>
                </tr>
              </React.Fragment>
            );
          })}
          </tbody>
        </Table>
      </CardBody>
      <div style={{ display: 'none' }}>
        <Button color="primary"
                id="0"
                style={{width: '96%', borderRadius: 0, background: '#4834d4', margin: '2%', marginTop: 0 }}
                onClick={() => { setVisible(true); }}
                size="md">Show All</Button>
      </div>
    </Card>
  );
};

const BuySellRatio = ({ data, trades }) => {
  const buyShares = data.raw.buy.slice(0,20).reduce((total, item) => (total + item.shares), 0);
  const sellShares = data.raw.sell.slice(0,20).reduce((total, item) => (total + item.shares), 0);
  const total = buyShares + sellShares;
  const buyPercentage = ((buyShares / total) * 100).toFixed(0);

  return (
    <React.Fragment>
      <Col sm="12">
        <Card style={{ background: '#c0392b', height: '2em', overflow: 'hidden', marginBottom: '1em', padding: 0 }}>
          <div style={{ width: `${buyPercentage}%`, height: '100%', background: '#218c74' }} />
        </Card>
      </Col>
      <Col xl="4" lg="6" md="6" sm="12">
        { Side('buy', 'Bid Orders | Buy', data) }
      </Col>
      <Col xl="4" lg="6" md="6" sm="12">
        { Side('sell' , 'Ask Orders | Sell', data) }
      </Col>
      <Col xl="4" lg="12" md="12" sm="12">
        { TradeTable(trades, data) }
      </Col>
    </React.Fragment>
  )
};

export default BuySellRatio;
