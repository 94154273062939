import React from 'react';
import Ratio from './ratio';
import RapidFire from './rapidFire';

const BuySellRatio = ({ data, trades }) => {
  return (
    <React.Fragment>
      <Ratio data={data} />
      <RapidFire data={data} />
    </React.Fragment>
  )
};

export default BuySellRatio;
